import type {
  SliceZoneProps,
  sliceSecaoEspecialidadesDestacadas
} from "_types/graphql/iSpecialties";
import React from "react";
import { FeaturedSpecialties } from "./FeaturedSpecialties";

const typesSlices = {
  secao_de_especialidades_destacadas: FeaturedSpecialties
};

export function SliceZone({ sliceZone }: { sliceZone: SliceZoneProps[] }) {
  const getSliceType = (sliceZone ?? []).map(item => {
    const Component = typesSlices[item?.slice_type];
    if (!Component) {
      return null;
    }
    if (item?.slice_type === "secao_de_especialidades_destacadas") {
      return (
        <FeaturedSpecialties
          data={item as sliceSecaoEspecialidadesDestacadas}
        />
      );
    }
    return null;
  });
  return <>{getSliceType}</>;
}
