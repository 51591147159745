import styled from "styled-components";
import { pxToRem } from "_utils/pxToRem";
import { LayoutGrid } from "presentation/style/shared";
import { breakpoints, colors } from "presentation/style/theme";

export const ContainerListAll = styled.section`
  ${LayoutGrid};
  > .list__title {
    color: ${colors.neutral[900]};
  }
  .list__line {
    background-color: ${colors.neutral[900]};
    margin-top: 16px;
    height: 1px;
    width: 100%;
    border: none;
  }
  @media screen and (max-width: 767px) {
    padding-top: 80px;
    padding-bottom: 80px;
    > .list__title {
      grid-column: 1 / span 2;
      font-size: ${pxToRem(28.83)};
      line-height: ${pxToRem(32)};
    }
    .list__line {
      grid-column: 1 / span 2;
    }
  }
  @media (min-width: ${breakpoints.md}) and (max-width: 1023px) {
    padding-top: 80px;
    padding-bottom: 80px;
    > .list__title {
      grid-column: 2 / span 6;
      font-size: ${pxToRem(39.81)};
      line-height: ${pxToRem(48)};
    }
    .list__line {
      grid-column: 2 / span 4;
    }
  }
  @media (min-width: ${breakpoints.lg}) and (max-width: 1919px) {
    padding-top: 160px;
    padding-bottom: 160px;
    > .list__title {
      grid-column: 2 / span 10;
      font-size: ${pxToRem(39.81)};
      line-height: ${pxToRem(48)};
    }
    .list__line {
      grid-column: 2 / span 4;
    }
  }
  @media screen and (min-width: ${breakpoints.xl}) {
    padding-top: 160px;
    padding-bottom: 160px;
    > .list__title {
      grid-column: 2 / span 7;
      font-size: ${pxToRem(48)};
      line-height: ${pxToRem(56)};
    }
    .list__line {
      grid-column: 2 / span 4;
    }
  }
`;
