import React from "react";
import { ReactSVG } from "react-svg";
import { Link } from "gatsby";
import { SendIcon } from "presentation/assets/icons";
import { colors } from "presentation/style/theme";
import { ContentListProps } from "_types/graphql/iSpecialties";
import { ContainerContentList } from "./style";

export function ContentList({ data }: ContentListProps): JSX.Element {
  const shouldRenderItems = data.length > 0;
  function getURLSVG(urlIcon?: string) {
    const position: number = urlIcon?.indexOf(".svg") ?? 0;
    return urlIcon?.substring(0, position + 4);
  }
  return (
    <ContainerContentList>
      <li className="list__item">
        <p className="specialties__title specialties__title--left">
          Especilidade
        </p>
        <p className="specialties__title specialties__title--right">
          Nº de exames
        </p>
      </li>
      {shouldRenderItems && (
        <li className="list__item">
          {(data ?? []).map(({ url, label, icon, amountExams }) => (
            <Link to={`/especialidade/${url}`} className="specialties__item">
              <div className="item__left">
                <ReactSVG
                  src={getURLSVG(icon) ?? ""}
                  className="specialties__icon"
                />
                <p className="item__text">{label}</p>
                {/* <PrismicRichText field={label} className="" /> */}
              </div>
              <div className="item__right">
                <p className="item__text item__text--right">{amountExams}</p>
                <div className="item__send">
                  <SendIcon
                    fill={colors.neutral[900]}
                    className="item__send-icon"
                  />
                </div>
              </div>
            </Link>
          ))}
        </li>
      )}
    </ContainerContentList>
  );
}
