import styled from "styled-components";
import { breakpoints, colors } from "presentation/style/theme";
import { CustomBackgroundSection, LayoutGrid } from "presentation/style/shared";
import { pxToRem } from "_utils/pxToRem";

export const ContainerHero = styled.section`
  ${CustomBackgroundSection};
  ::after {
    background-color: ${colors.primary[400]};
  }
  width: 100%;
  .hero__image {
    object-fit: cover;
    width: 100%;
    border-end-end-radius: 88px;
  }
  .hero__infos {
    padding-top: 64px;
    ${LayoutGrid}
    > .hero__title,
    > .hero__description {
      color: ${colors.neutral[100]};
    }
    > .hero__description {
      line-height: ${pxToRem(24)};
    }
  }

  @media screen and (max-width: 767px) {
    .hero__image {
      height: ${pxToRem(424)};
    }
    .hero__infos {
      padding-bottom: 72px;
      > .hero__title {
        grid-column: 1 / span 2;
        font-size: ${pxToRem(32.44)};
        line-height: ${pxToRem(32)};
      }
      > .hero__description {
        grid-column: 1 / span 2;
        margin-top: 32px;
        font-size: ${pxToRem(18)};
      }
    }
  }
  @media (min-width: ${breakpoints.md}) and (max-width: 1023px) {
    .hero__image {
      height: ${pxToRem(680)};
    }
    .hero__infos {
      padding-bottom: 72.33px;
      > .hero__title {
        grid-column: 2 / span 6;
        font-size: ${pxToRem(47.78)};
        line-height: ${pxToRem(40)};
      }
      > .hero__description {
        margin-top: 32px;
        grid-column: 2 / span 6;
        font-size: ${pxToRem(19.2)};
      }
    }
  }
  @media (min-width: ${breakpoints.lg}) and (max-width: 1919px) {
    .hero__image {
      height: ${pxToRem(460)};
    }
    .hero__infos {
      padding-bottom: 52px;
      > .hero__title {
        grid-column: 2 / span 6;
        font-size: ${pxToRem(47.78)};
        line-height: ${pxToRem(40)};
      }
      > .hero__description {
        grid-column: 8 / span 4;
        font-size: ${pxToRem(19.2)};
      }
    }
  }
  @media screen and (min-width: ${breakpoints.xl}) {
    .hero__image {
      height: ${pxToRem(776)};
    }
    .hero__infos {
      padding-bottom: 96px;
      > .hero__title {
        grid-column: 2 / span 5;
        font-size: ${pxToRem(62)};
        line-height: ${pxToRem(56)};
      }
      > .hero__description {
        grid-column: 9 / span 3;
        font-size: ${pxToRem(20)};
      }
    }
  }
`;
