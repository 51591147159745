import styled from "styled-components";
import { breakpoints, colors } from "presentation/style/theme";
import { pxToRem } from "_utils/pxToRem";
import { LayoutGridClean, LayoutGridSpace } from "presentation/style/shared";

export const ContainerContentList = styled.ul`
  list-style: none;
  padding: 0;

  .list__item {
    ${LayoutGridClean};
    .specialties__title {
      margin-bottom: 16px;
      padding: 0 16px;
      font-weight: 300;
      font-size: ${pxToRem(16)};
      line-height: ${pxToRem(24)};
      color: ${colors.neutral[900]};
    }
    .specialties__item {
      border-color: ${colors.neutral[900]};
      border-top-width: 1px;
      border-style: solid;
      ${LayoutGridSpace};
      width: 100%;
      text-decoration: none;
      position: relative;
      z-index: 1;
      cursor: pointer;
      ::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0;
        background-color: #f1e8e5;
        transition: height 0.25s ease-out;
        z-index: -1;
      }
      .item__text {
        color: ${colors.neutral[900]};
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        transition: 0.2s ease;
      }
      .item__right,
      .item__left {
        display: flex;
        align-items: center;
        padding-left: 16px;
        padding-right: 16px;
      }
      .item__left {
        column-gap: 16px;
        .specialties__icon {
          svg {
            width: ${pxToRem(26)};
            height: ${pxToRem(26)};
            display: block;
            path {
              transition: 0.2s ease;
              fill: ${colors.neutral[900]};
            }
          }
        }
      }
      .item__right {
        justify-content: space-between;
        .item__send {
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          height: ${pxToRem(48)};
          width: ${pxToRem(48)};
          transition: 0.2s ease;
        }
      }
      :hover {
        .item__text {
          color: ${colors.neutral[900]};
          font-weight: 600;
        }
        .item__right .item__send {
          background-color: #e3cec7;
        }
        :before {
          height: 100%;
        }
      }
    }
    .specialties__item:last-child {
      border-bottom-width: 1px;
    }
  }
  @media screen and (max-width: 767px) {
    margin: 45px 0 0;
    grid-column: 1 / span 2;
    .list__item {
      grid-template-columns: repeat(2, 1fr);
      .specialties__title--left {
        margin-bottom: 8px;
        grid-column: 1 / span 2;
      }
      .specialties__title--right {
        display: none;
      }
      .specialties__item {
        grid-column: 1 / span 2;
        grid-template-columns: repeat(2, 1fr);
        padding-top: 6px;
        padding-bottom: 6px;
        .item__text {
          font-size: ${pxToRem(22.78)};
          line-height: ${pxToRem(32)};
        }
        .item__text--right {
          display: none;
        }
        .item__left {
          padding-right: ${pxToRem(50)};
          grid-column: 1 / span 2;
          grid-row: 1 / span 1;
        }
        .item__right {
          grid-row: 1 / span 1;
          grid-column: 1 / span 2;
          width: ${pxToRem(50)};
          margin: auto 0 auto auto;
          padding: 0;
        }
      }
    }
  }
  @media (min-width: ${breakpoints.md}) and (max-width: 1023px) {
    margin: 80px 0 0;
    grid-column: 2 / span 6;
    .list__item {
      grid-template-columns: repeat(6, 1fr);
      .specialties__title--left {
        grid-column: 1 / span 6;
      }
      .specialties__title--right {
        display: none;
      }
      .specialties__item {
        grid-column: 1 / span 6;
        grid-template-columns: repeat(6, 1fr);
        padding-top: 20px;
        padding-bottom: 20px;
        .item__text {
          font-size: ${pxToRem(27.65)};
          line-height: ${pxToRem(32)};
        }
        .item__text--right {
          display: none;
        }
        .item__left {
          grid-column: 1 / span 5;
        }
        .item__right {
          grid-column: 6 / span 1;
        }
      }
    }
  }
  @media (min-width: ${breakpoints.lg}) and (max-width: 1919px) {
    margin: 40px 0 0;
    grid-column: 2 / span 10;
    .list__item {
      grid-template-columns: repeat(10, 1fr);
      .specialties__title--left {
        grid-column: 1 / span 3;
      }
      .specialties__title--right {
        grid-column: 8 / span 3;
        width: 100%;
      }
      .specialties__item {
        grid-column: 1 / span 10;
        grid-template-columns: repeat(10, 1fr);
        padding-top: 12px;
        padding-bottom: 12px;
        .item__text {
          font-size: ${pxToRem(27.65)};
          line-height: ${pxToRem(32)};
        }
        .item__left {
          grid-column: 1 / span 7;
        }
        .item__right {
          grid-column: 8 / span 3;
        }
      }
    }
  }
  @media screen and (min-width: ${breakpoints.xl}) {
    margin: 80px 0 0;
    grid-column: 2 / span 10;
    .list__item {
      grid-template-columns: repeat(10, 1fr);
      .specialties__title--left {
        grid-column: 1 / span 3;
      }
      .specialties__title--right {
        grid-column: 9 / span 2;
        width: 100%;
      }
      .specialties__item {
        grid-column: 1 / span 10;
        grid-template-columns: repeat(10, 1fr);
        padding-top: 24px;
        padding-bottom: 24px;
        .item__text {
          font-size: ${pxToRem(31.25)};
          line-height: ${pxToRem(40)};
        }
        .item__left {
          grid-column: 1 / span 8;
        }
        .item__right {
          grid-column: 9 / span 2;
        }
      }
    }
  }
`;
