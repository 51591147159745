import React from "react";
import {
  GatsbyImage,
  getImage,
  IGatsbyImageData,
  ImageDataLike
} from "gatsby-plugin-image";
import { PrismicRichText } from "presentation/components";
import type { HeroProps } from "_types/graphql/iSpecialties";
import { ContainerHero } from "./style";

export function Hero({ image, title, description }: HeroProps) {
  function getImg(value: ImageDataLike) {
    return getImage(value) as IGatsbyImageData;
  }
  return (
    <ContainerHero>
      <GatsbyImage
        className="hero__image"
        image={getImg(image)}
        alt="Area hero"
      />
      <div className="hero__infos">
        <PrismicRichText className="hero__title" field={title} />
        <PrismicRichText className="hero__description" field={description} />
      </div>
    </ContainerHero>
  );
}
