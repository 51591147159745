import React from "react";
import { SpecialtiesProps } from "_types/graphql/iSpecialties";
import { CTASection, QualityWarranty } from "presentation/components";
import { Hero } from "./partials/Hero";
import { ContainerSliceZone } from "./style";
import { SliceZone } from "./partials/SliceZone";
import { ListAll } from "./partials/ListAll";

export function Specialties({
  dataPage,
  ctaContato,
  sectionQualityWarranty,
  sectionListAll
}: SpecialtiesProps) {
  const dataHero = {
    image: dataPage?.sectionHero?.imagem_destacada,
    title: dataPage?.sectionHero?.titulo_da_pagina?.richText,
    description: dataPage?.sectionHero?.descricao_curta?.richText
  };
  return (
    <>
      <Hero {...dataHero} />
      <ContainerSliceZone>
        <SliceZone sliceZone={dataPage.contentSlices.body} />
      </ContainerSliceZone>
      <ListAll data={sectionListAll} />
      <CTASection data={ctaContato} />
      <QualityWarranty data={sectionQualityWarranty} />
    </>
  );
}
