import React from "react";
import { ListAllProps } from "_types/graphql/iSpecialties";
import { ContainerListAll } from "./style";
import { ContentList } from "./partials/ContentList";

export function ListAll({ data }: ListAllProps): JSX.Element {
  return (
    <ContainerListAll>
      <h2 className="list__title">
        Conheça todas
        <strong> as especialidades.</strong>
      </h2>
      <hr className="list__line" />
      <ContentList data={data} />
    </ContainerListAll>
  );
}
