import styled, { css } from "styled-components";
import { breakpoints, colors } from "presentation/style/theme";
import { LayoutGrid, LayoutGridSpace } from "presentation/style/shared";
import { pxToRem } from "_utils/pxToRem";

export const ContainerFeaturedSpecialties = styled.section`
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 1023px) {
    row-gap: 80px;
    padding-bottom: 80px;
  }
  @media (min-width: ${breakpoints.lg}) and (max-width: 1919px) {
    padding-bottom: 160px;
  }
  @media screen and (min-width: ${breakpoints.xl}) {
    padding-bottom: 100px;
  }
`;

const modifier = {
  left: css`
    @media (min-width: ${breakpoints.lg}) and (max-width: 1919px) {
      :nth-child(1) {
        align-items: end;
      }
      :nth-child(2) {
        align-items: start;
        .specialties__data {
          margin-top: 62px;
        }
      }
      .specialties__data {
        grid-column: 2 / span 6;
      }
      .specialist__image {
        grid-row: 1 / span 1;
      }
      :nth-child(1) .specialist__image {
        grid-column: 7 / span 5;
        width: 330px;
        height: 245px;
        margin-left: 32px;
      }
      :nth-child(2) {
        margin-top: -32px;
        .specialist__image {
          grid-column: 8 / span 4;
          width: 100%;
          max-width: 310px;
          height: 98%;
        }
      }
    }
    @media screen and (min-width: ${breakpoints.xl}) {
      .specialties__data {
        grid-column: 2 / span 5;
      }
      .specialist__image {
        grid-column: 7 / span 5;
        grid-row: 1 / span 1;
      }
      :nth-child(1) .specialist__image {
        width: 485px;
        height: 360px;
        margin-top: -24px;
      }
      :nth-child(2) .specialist__image {
        width: calc(100% - 54px);
        max-width: 700px;
        max-height: 522px;
        margin-top: -24px;
        margin-left: 54px;
      }
    }
  `,
  right: css`
    @media (min-width: ${breakpoints.lg}) and (max-width: 1919px) {
      align-items: start;
      :nth-child(3) {
        margin-top: 150px;
      }
      .specialties__data {
        grid-column: 6 / span 6;
      }
      .specialist__image {
        grid-row: 1 / span 1;
      }
      :nth-child(3) .specialist__image {
        grid-column: 2 / span 3;
        width: 100%;
        max-width: 250px;
        height: 458px;
      }
      :nth-child(4) {
        margin-top: -32px;
        .specialist__image {
          margin-left: 36px;
          grid-column: 2 / span 4;
          width: 250px;
          height: 286px;
        }
      }
    }
    @media screen and (min-width: ${breakpoints.xl}) {
      .specialties__data {
        grid-column: 7 / span 5;
      }
      .specialist__image {
        grid-column: 2 / span 5;
        grid-row: 1 / span 1;
      }
      :nth-child(3) {
        margin-top: 44px;
        .specialist__image {
          width: 100%;
          max-width: 556px;
          height: 586px;
        }
      }
      :nth-child(4) {
        margin-top: -95px;
        .specialist__image {
          align-self: end;
          justify-self: end;
          margin-right: 62px;
          width: 100%;
          max-width: 511px;
          height: 408px;
        }
      }
    }
  `
};
export const SpecialtiesItem = styled.div<{ side: "left" | "right" }>`
  ${LayoutGrid};
  align-items: center;
  .specialties__data {
    ${LayoutGridSpace};
    height: fit-content;
    > .specialist__title {
      color: ${colors.neutral[900]};
    }
    .specialist__line {
      background-color: ${colors.neutral[900]};
      margin-top: 16px;
      height: 1px;
      width: 100%;
      border: none;
    }
    > .specialist__description {
      color: ${colors.neutral[700]};
      font-size: ${pxToRem(16)};
      line-height: ${pxToRem(24)};
      margin-top: 32px;
    }
    .specialist__button {
      width: 100%;
      margin-top: 40px;
    }
  }
  .specialist__image {
    border-radius: 16px;
    filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.16));
  }
  @media screen and (max-width: 767px) {
    .specialties__data {
      grid-template-columns: repeat(2, 1fr);
      grid-column: 1 / span 2;
      margin-top: 32px;
      > .specialist__title {
        grid-column: 1 / span 2;
        font-size: ${pxToRem(32.44)};
        line-height: ${pxToRem(32)};
      }
      .specialist__button {
        margin-top: 32px;
      }
      .specialist__button,
      > .specialist__description {
        grid-column: 1 / span 3;
      }
      .specialist__line {
        grid-column: 1 / span 1;
      }
    }
    .specialist__image {
      grid-row: 1 / span 1;
      grid-column: 1 / span 2;
      width: 100%;
      height: 80px;
    }
  }

  @media (min-width: ${breakpoints.md}) and (max-width: 1023px) {
    .specialties__data {
      grid-template-columns: repeat(6, 1fr);
      grid-column: 2 / span 6;
      margin-top: 40px;
      > .specialist__title {
        grid-column: 1 / span 5;
        font-size: ${pxToRem(47.78)};
        line-height: ${pxToRem(40)};
      }
      .specialist__line,
      .specialist__button {
        grid-column: 1 / span 3;
      }
      > .specialist__description {
        grid-column: 1 / span 5;
      }
    }
    .specialist__image {
      grid-row: 1 / span 1;
      grid-column: 2 / span 6;
      width: 100%;
      height: 120px;
    }
  }

  @media (min-width: ${breakpoints.lg}) and (max-width: 1919px) {
    .specialties__data {
      grid-template-columns: repeat(6, 1fr);
      grid-row: 1 / span 1;
      > .specialist__title {
        grid-column: 1 / span 6;
        font-size: ${pxToRem(47.78)};
        line-height: ${pxToRem(40)};
      }
      .specialist__line,
      .specialist__button {
        grid-column: 1 / span 4;
      }
      > .specialist__description {
        grid-column: 1 / span 5;
      }
    }
    :nth-child(2) {
      .specialist__title {
        :first-child {
          margin-top: 70px;
        }
      }
    }
  }

  @media screen and (min-width: ${breakpoints.xl}) {
    .specialties__data {
      grid-template-columns: repeat(5, 1fr);
      grid-row: 1 / span 1;
      > .specialist__title {
        grid-column: 1 / span 5;
        font-size: ${pxToRem(62)};
        line-height: ${pxToRem(56)};
      }
      .specialist__line {
        grid-column: 1 / span 3;
      }
      > .specialist__description {
        grid-column: 1 / span 4;
      }
      .specialist__button {
        grid-column: 1 / span 2;
      }
    }
  }
  ${({ side }) => modifier[side]}
`;
