import React from "react";
import {
  GatsbyImage,
  IGatsbyImageData,
  ImageDataLike,
  getImage
} from "gatsby-plugin-image";
import type { sliceSecaoEspecialidadesDestacadas } from "_types/graphql/iSpecialties";
import { Button, PrismicRichText } from "presentation/components";
import { ContainerFeaturedSpecialties, SpecialtiesItem } from "./style";

export function FeaturedSpecialties({
  data
}: {
  data: sliceSecaoEspecialidadesDestacadas;
}): JSX.Element {
  const { items } = data;
  function getImg(value: ImageDataLike) {
    return getImage(value) as IGatsbyImageData;
  }
  return (
    <ContainerFeaturedSpecialties>
      {(items ?? []).slice(0, 4).map((item, index) => {
        const position = index;
        const side = position < 2 ? "left" : "right";
        return (
          <SpecialtiesItem key={position} side={side}>
            <div className="specialties__data">
              <PrismicRichText
                className="specialist__title"
                field={item?.titulo_nome_da_especialidade?.richText}
              />
              <hr className="specialist__line" />
              <PrismicRichText
                className="specialist__description"
                field={item?.descritivo_curto_da_especialidade?.richText}
              />
              {item?.seletor_de_especialidade?.uid && (
                <Button
                  navigateTo={`/especialidade/${String(
                    item?.seletor_de_especialidade?.uid
                  )}`}
                  title="Saiba mais"
                  className="specialist__button"
                  iconName="RightArrowIcon"
                />
              )}
            </div>
            <GatsbyImage
              image={getImg(item?.imagem)}
              className="specialist__image"
              alt={item?.imagem.alt}
            />
          </SpecialtiesItem>
        );
      })}
    </ContainerFeaturedSpecialties>
  );
}
