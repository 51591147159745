import { breakpoints } from "presentation/style/theme";
import styled from "styled-components";

export const ContainerSliceZone = styled.div`
  @media screen and (max-width: 767px) {
    margin-top: 80px;
  }
  @media (min-width: ${breakpoints.md}) and (max-width: 1023px) {
    margin-top: 80px;
  }
  @media (min-width: ${breakpoints.lg}) and (max-width: 1919px) {
    margin-top: 160px;
  }
  @media screen and (min-width: ${breakpoints.xl}) {
    margin-top: 150px;
  }
`;
